<form [formGroup]="form.getGroup()" id="advertForm">
    <div class="row">
        <div class="col-md">
            <div class="row align-items-center">
                <label for="input1" class="col-sm-4 col-form-label">{{
                    'web.basic_contact_customer' | translate
                }}</label>
                <div class="col-sm-8">
                    <a href="mailto:info@24live.com">info@24live.com</a>
                </div>
            </div>

            <div class="row align-items-center">
                <label for="input1" class="col-sm-4 col-form-label">{{ 'web.marketing_contact' | translate }}</label>
                <div class="col-sm-8">
                    <a
                        href="mailto:
marketing@score-stats.com"
                    >
                        marketing@score-stats.com</a
                    >
                </div>
            </div>
            <div class="row align-items-center">
                <label for="input1" class="col-sm-4 col-form-label">{{
                    'web.basic_contact_business' | translate
                }}</label>
                <div class="col-sm-8">
                    <a href="mailto:sales@score-stats.com">sales@score-stats.com</a>
                </div>
            </div>
            <h6 class="text-uppercase mb-3 mt-4 font-weight-bold">{{ 'web.contact_address' | translate }}</h6>
            <article class="mb-3">
                EDDIE s.r.o <br />
                IČO: 26158922 <br />
                Holušická 2253/1, <br />
                148 00 Praha, Chodov
            </article>
        </div>
    </div>

    <div class="row mt-4 bg-gray p-4">
        <div class="col-12">
            <p *ngIf="success" class="text-success">{{ 'web.form_send_success' | translate }}</p>
            <p *ngIf="error2" class="text-danger">{{ 'web.form_send_error' | translate }}</p>

            <div
                class="alert alert-danger"
                role="alert"
                *ngIf="form.getGroup().errors && (form.getGroup().touched || form.getGroup().dirty)"
            >
                {{ form.getErrorMessage(form.getGroup()) | translate }}
            </div>

            <div class="form-group row">
                <label for="input1" class="col-sm-3 col-form-label">{{ 'web.name' | translate }}</label>
                <div class="col-sm-9">
                    <input
                        matInput
                        placeholder="{{ 'web.enter_name' | translate }}"
                        class="form-control form-control-sm"
                        formControlName="name"
                        name="name"
                        autocomplete="name"
                    />
                    <mat-error *ngIf="form.name?.invalid && !form.name?.pristine">{{
                        form.getErrorMessage(form.name) | translate
                    }}</mat-error>
                </div>
            </div>

            <div class="form-group row">
                <label for="input3" class="col-sm-3 col-form-label">{{ 'web.email' | translate }}</label>
                <div class="col-sm-9">
                    <input
                        matInput
                        placeholder="{{ 'web.enter_email' | translate }}"
                        class="form-control form-control-sm"
                        formControlName="email"
                        name="email"
                        autocomplete="email"
                    />
                    <mat-error *ngIf="form.email?.invalid && !form.email?.pristine">{{
                        form.getErrorMessage(form.email) | translate
                    }}</mat-error>
                </div>
            </div>

            <div class="form-group row">
                <label for="input3" class="col-sm-3 col-form-label">{{ 'web.text_message' | translate }}</label>
                <div class="col-sm-9">
                    <textarea
                        matInput
                        placeholder="{{ 'web.enter_text' | translate }}"
                        cols="10"
                        rows="4"
                        formControlName="text"
                        style="height: auto !important"
                    ></textarea>
                    <mat-error *ngIf="form.text?.invalid && !form.text?.pristine">{{
                        form.getErrorMessage(form.text) | translate
                    }}</mat-error>
                </div>
            </div>
            <div>
                <ngx-recaptcha2
                    #captchaElem
                    [siteKey]="siteKey"
                    (load)="handleLoadRC()"
                    (success)="handleSuccessRC($event)"
                    [useGlobalDomain]="false"
                    [hl]="lang.getLangSnapshot()"
                    [type]="'Checkbox'"
                    formControlName="recaptcha"
                >
                </ngx-recaptcha2>

                <span class="text-danger" *ngIf="form.recaptcha?.invalid">{{
                    form.getErrorMessage(form.recaptcha) | translate
                }}</span>
            </div>
            <p class="text-right">
                <button class="btn btn-primary rounded-0" [disabled]="form.getGroup().invalid" (click)="send()">
                    {{ 'web.' + submitText | translate }}
                </button>
                &nbsp;&nbsp;
                <button class="btn btn-outline-secondary" type="reset" (click)="resetForm()">
                    {{ 'web.reset_form' | translate }}
                </button>
            </p>
        </div>
    </div>
</form>
