<div class="row">
    <div class="col-md">
        <article class="text-justify">
            {{ 'web.privacy_text' | translate }}
        </article>

        <div class="pl-table mt-4" role="table">
            <div class="pl-tbody">
                <div class="row">
                    <div class="col">{{ 'web.type' | translate }}</div>
                    <div class="col">{{ 'web.source' | translate }}</div>
                    <div class="col">{{ 'web.purpose' | translate }}</div>
                    <div class="col">{{ 'web.expiration' | translate }}</div>
                </div>
                <div class="row">
                    <div class="col">{{ 'web.cookie_3rd_party' | translate }}</div>
                    <div class="col">Google analytics</div>
                    <div class="col">{{ 'web.cookie_gain_info' | translate }}</div>
                    <div class="col">{{ 'web.cookie_expiration_browser' | translate }}</div>
                </div>
            </div>
        </div>

        <p>{{ 'web.security_team' | translate }}: <a href="mailto:info@24live.com">info@24live.com</a></p>

        <h3>{{ 'web.privacy_head_2' | translate }}</h3>
        <p class="text-justify">
            {{ 'web.privacy_text_2' | translate }}
        </p>
    </div>
</div>
