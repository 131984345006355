/**
 *  Service for page and client info (lang)
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, distinctUntilChanged } from 'rxjs/operators';


import { CommonService } from '../shared/common.service';
import URL_CONFIG from '../config/url.config';
import { StatusInterface } from '../interfaces/status.interface';
import { AdvertFormInterface } from '../interfaces/advert-form.interface';

import { FormErrorInterface } from '@/interfaces/form-response.interface';

@Injectable({
    providedIn: 'root',
})
export class MarketingService {
    constructor(private http: HttpClient, private common: CommonService) {}

    /**
   * Send contact form info
   * @param {AdvertFormInterface}
   * @return {Observable<StatusInterface>}
   */
    public contact(data: AdvertFormInterface,
        type: 'info' | 'marketing' = 'marketing'): Observable<StatusInterface | FormErrorInterface> {
        const url = `${URL_CONFIG.api.advertiseForm}?type=${type}`;

        return this.http.post<StatusInterface>(url, data).pipe(
            distinctUntilChanged(),
            switchMap(this.map),
            catchError(this.common.errorFormCallback), // then handle the error
        );
    }

    private map(data: StatusInterface): Observable<StatusInterface> {
        return of(data);
    }
}
