import { Component, OnInit, ViewChild } from '@angular/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { InfoService } from 'src/app/services/info.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import APP_CONFIG from 'src/app/config/app.config';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AdvertiseForm } from '@shared/forms/advertise.form';

import { MatChipInputEvent } from '@angular/material/chips';

import { MarketingService } from '@services/marketing.service';

import { BaseComponent } from '../../base.component';
import { ClientService } from '../../../services/client.service';
import { LangService } from '../../../services/lang.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
})
export class ContactComponent extends PageMixin(BaseComponent) implements OnInit {
    @ViewChild('captchaElem', { static: true })
    public captcha: any;

    public siteKey: string = APP_CONFIG.recaptchaSiteKey;

    public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    public addOnBlur: boolean = true;

    public selectable: boolean = true;

    public removable: boolean = true;

    public submitText: string = 'submit_form_adv';

    public websites: string[] = [];

    public formLoaded: boolean;

    /**
     * True in case of success
     */
    public success: boolean = false;

    /**
     * True in case of error
     */
    public error2: boolean = false;

    public constructor(
        protected page: PageService,
        protected translate: TranslateService,
        protected client: ClientService,
        public lang: LangService,
        public form: AdvertiseForm,
        public marketing: MarketingService,
        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.lang.getLang().then((): Promise<any> => this.createTitle());
        this.page.setPageTitle('web.contact_us');
    }

    public handleLoadRC(): void {
        this.formLoaded = true;
    }

    public handleSuccessRC(token: any): void {
        this.form.patchValue({
            recaptcha: token,
        });
    }


    /**
     * Send message
     * @return [description]
     */
    public send(): void {
        this.submitText = 'form_loading_text';
        this.form.getGroup().disable();
        this.marketing.contact(this.form.getGroup().value, 'info').subscribe(
            (): void => {
                this.resetForm();
                this.success = true;
            },
            (er): void => {
                this.form.getGroup().enable();
                this.captcha.resetCaptcha();
                this.submitText = 'submit_form_adv';
                this.error2 = true;
                this.form.setErrors(er);
            },
        );
    }

    public add(event: MatChipInputEvent): void {
        const { input } = event;
        const { value } = event;

        /*eslint-disable */
        const test = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/.test(
            value
        );
        /* eslint-enable */
        if (test && (value || '').trim()) {
            this.websites.push(value.trim());
        }
        this.form.patchValue({ web: this.websites });
        if (input) {
            input.value = '';
        }
    }

    public remove(web: string): void {
        const index = this.websites.indexOf(web);
        if (index >= 0) {
            this.websites.splice(index, 1);
        }
    }

    /**
     * Reset forn
     */
    public resetForm(): void {
        this.form.getGroup().enable();
        // this.form.getGroup().reset();
        const elm: any = document.getElementById('advertForm');
        elm.reset();
        this.captcha.resetCaptcha();
        this.submitText = 'submit_form_adv';
        this.websites.splice(0);
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_contact').subscribe((): void => {
            const trans = this.translate.instant('web.title_contact');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${trans} | ${client}`,
                    description: this.translate.instant('web.description_contact'),
                },
                true,
            );
        });
    }
}
