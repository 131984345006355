<div class="row">
    <div class="col-12">
        <p class="text-justify">
            <strong>{{ 'web.about_1' | translate }}</strong>
        </p>
    </div>
</div>

<div class="row">
    <div class="col mb-4">
        <img
            src="/img/24live_livescore_aboutus_catch_every_moment.jpg?v3"
            alt="You can use on mobile phone"
            class="img-fluid"
        />
    </div>
</div>

<div class="row">
    <div class="col mb-4 text-center">
        <h2 class="font-weight-bold">{{ 'web.about_3' | translate }}</h2>
    </div>
</div>

<div class="row">
    <div class="col-12 mb-4">
        <p class="text-justify">
            {{ 'web.about_4' | translate }}
        </p>
    </div>
</div>

<div class="row">
    <div class="col">
        <div class="d-flex d-flex justify-content-between">
            <div class="about-info-item d-flex flex-column justify-content-center align-items-center">
                <img src="/img/ico_sports.svg" alt="You can use on mobile phone" class="img-fluid" />

                <p class="mb-0 about-info-item__text about-info-item__text--emph">27+</p>
                <p class="mb-0 about-info-item__text">{{ 'web.about_sport' | translate }}</p>
            </div>
            <div class="about-info-item d-flex flex-column justify-content-center align-items-center">
                <img src="/img/ico_cup.svg" alt="You can use on mobile phone" class="img-fluid" />

                <p class="mb-0 about-info-item__text about-info-item__text--emph">9000+</p>
                <p class="mb-0 about-info-item__text">{{ 'web.about_competition' | translate }}</p>
            </div>
            <div class="about-info-item d-flex flex-column justify-content-center align-items-center">
                <img src="/img/ico_language.svg" alt="Live matches" class="img-fluid" />

                <p class="mb-0 about-info-item__text about-info-item__text--emph">44</p>
                <p class="mb-0 about-info-item__text">{{ 'web.about_languages' | translate }}</p>
            </div>
        </div>
    </div>
</div>

<div class="row mt-4">
    <div class="col-12">
        <p class="text-justify">
            {{ 'web.about_2' | translate }}
        </p>
    </div>
</div>
