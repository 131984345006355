import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ComponentModule } from '@components/component.module';


import { PageRegularComponent } from '@pages/page-regular/page-regular.component';


import { ContactComponent } from '@pages/page-regular/contact/contact.component';
import { ConditionsComponent } from '@pages/page-regular/conditions/conditions.component';
import { AdvertiseComponent } from '@pages/page-regular/advertise/advertise.component';
import { PrivacyComponent } from '@pages/page-regular/privacy/privacy.component';
import { AboutUsComponent } from '@pages/page-regular/about-us/about-us.component';

import { RegularRoutingModule } from '@pages/page-regular/regular-routing.module';
import { AddToHomescreenComponent } from '@pages/page-regular/add-to-homescreen/add-to-homescreen.component';
import { LivescoreComponent } from '@pages/page-regular/livescore/livescore.component';

import { ApplicationComponent } from '@pages/page-regular/application/application.component';

import { SharedModule } from '@/shared/shared.module';


@NgModule({
    declarations: [
        ContactComponent, ConditionsComponent, AdvertiseComponent, ApplicationComponent,
        PrivacyComponent, AboutUsComponent, PageRegularComponent, AddToHomescreenComponent,
        LivescoreComponent,
    ],
    imports: [CommonModule, SharedModule, RegularRoutingModule, ComponentModule],
})
export class RegularModule {}
