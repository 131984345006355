import { Component, OnInit, ViewChild } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import APP_CONFIG from 'src/app/config/app.config';

import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { InfoService } from 'src/app/services/info.service';
import { Router } from '@angular/router';
import { AdvertiseForm } from 'src/app/shared/forms/advertise.form';
import { MarketingService } from 'src/app/services/marketing.service';
import { LangService } from 'src/app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';

import { BaseComponent } from '../../base.component';
import { ClientService } from '../../../services/client.service';

@Component({
    selector: 'app-advertise',
    templateUrl: './advertise.component.html',
})
export class AdvertiseComponent extends PageMixin(BaseComponent) implements OnInit {
    @ViewChild('captchaElem', { static: true })
    public captcha: any;

    public siteKey: string = APP_CONFIG.recaptchaSiteKey;

    public readonly separatorKeysCodes: number[] = [ENTER, COMMA];

    public addOnBlur: boolean = true;

    public selectable: boolean = true;

    public removable: boolean = true;

    public submitText: string = 'submit_form_adv';


    public formLoaded: boolean;

    /**
     * True in case of success
     */
    public success: boolean = false;

    /**
     * True in case of error
     */
    public error2: boolean = false;

    public constructor(
        public page: PageService,
        public form: AdvertiseForm,
        public marketing: MarketingService,
        public lang: LangService,
        protected client: ClientService,
        protected translate: TranslateService,
        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.lang.getLang().then((): Promise<any> => this.createTitle());

        this.page.setPageTitle('web.advertisement');

        this.form.getGroup().valueChanges.subscribe((): void => {
            this.error2 = false;
            this.success = false;
        });
    }

    public handleLoadRC(): void {
        this.formLoaded = true;
    }

    public handleSuccessRC(token: any): void {
        this.form.patchValue({
            recaptcha: token,
        });
    }

    /**
     * Send message
     * @return [description]
     */
    public send(): void {
        this.submitText = 'form_loading_text';
        this.form.getGroup().disable();
        this.marketing.contact(this.form.getGroup().value).subscribe(
            (): void => {
                this.resetForm();
                this.success = true;
            },
            (er): void => {
                this.form.getGroup().enable();
                this.captcha.resetCaptcha();
                this.submitText = 'submit_form_adv';
                this.error2 = true;
                this.form.setErrors(er);
            },
        );
    }


    /**
     * Reset forn
     */
    public resetForm(): void {
        this.form.getGroup().enable();
        // this.form.getGroup().reset();
        const elm: any = document.getElementById('advertForm');
        elm.reset();
        this.captcha.resetCaptcha();
        this.submitText = 'submit_form_adv';
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_advertise').subscribe((): void => {
            const trans = this.translate.instant('web.title_advertise');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${trans} | ${client}`,
                    description: this.translate.instant('web.description_advertise'),
                },
                true,
            );
        });
    }
}
