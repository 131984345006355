import { Component } from '@angular/core';

import { PageService } from '@services/page.service';

@Component({
    selector: 'app-page-regular',
    templateUrl: './page-regular.component.html',
})
export class PageRegularComponent {
    constructor(public page: PageService) {

    }
}
