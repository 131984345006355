<form [formGroup]="form.getGroup()" id="advertForm">
    <div class="row">
        <div class="col-12">
            <p class="text-justify">
                <strong>
                    {{ 'web.advert_1' | translate }}
                </strong>
            </p>
        </div>
    </div>

    <div class="row">
        <div class="col-6 offset-3 mb-4">
            <img src="/img/letadv.png" alt="You can use on mobile phone" class="img-fluid" />
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p class="text-justify">{{ 'web.advert_2' | translate }}</p>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <p class="text-justify">
                <strong> {{ 'web.advert_3' | translate }}</strong>
            </p>
        </div>
        <div class="col"><img src="/img/desktop_1.svg" alt="You can use on mobile phone" class="img-fluid" /></div>
        <div class="col"><img src="/img/desktop_2.svg" alt="You can use on mobile phone" class="img-fluid" /></div>
        <div class="col-12">
            <p>
                <strong> {{ 'web.advert_4' | translate }}</strong>
            </p>
        </div>
        <div class="col"><img src="/img/mobile_1.svg" alt="You can use on mobile phone" class="img-fluid" /></div>
        <div class="col"><img src="/img/mobile_2.svg" alt="You can use on mobile phone" class="img-fluid" /></div>
        <div class="col"><img src="/img/mobile_3.svg" alt="You can use on mobile phone" class="img-fluid" /></div>
        <div class="col"><img src="/img/mobile_4.svg" alt="You can use on mobile phone" class="img-fluid" /></div>
    </div>

    <div class="row mt-4">
        <div class="col-12">
            <h6 class="mb-3 font-1-7x font-weight-bold">{{ 'web.advert_5' | translate }}</h6>
        </div>
    </div>
    <div class="row bg-gray p-4">
        <div class="col-12">
            <p *ngIf="success" class="text-success">{{ 'form_send_success' | translate }}</p>
            <p *ngIf="error2" class="text-danger">{{ 'form_send_error' | translate }}</p>

            <div
                class="alert alert-danger"
                role="alert"
                *ngIf="form.getGroup().errors && (form.getGroup().touched || form.getGroup().dirty)"
            >
                {{ form.getErrorMessage(form.getGroup()) | translate }}
            </div>

            <div class="form-group row">
                <label for="input1" class="col-sm-4 col-form-label">{{ 'web.name' | translate }}</label>
                <div class="col-sm-8">
                    <input
                        matInput
                        placeholder="{{ 'web.enter_name' | translate }}"
                        class="form-control form-control-sm"
                        formControlName="name"
                        name="name"
                        autocomplete="name"
                    />
                    <mat-error *ngIf="form.name?.invalid && !form.name?.pristine">{{
                        form.getErrorMessage(form.name) | translate
                    }}</mat-error>
                </div>
            </div>

            <div class="form-group row">
                <label for="input3" class="col-sm-4 col-form-label">{{ 'web.email' | translate }}</label>
                <div class="col-sm-8">
                    <input
                        matInput
                        placeholder="{{ 'web.enter_email' | translate }}"
                        class="form-control form-control-sm"
                        formControlName="email"
                        name="email"
                        autocomplete="email"
                    />
                    <mat-error *ngIf="form.email?.invalid && !form.email?.pristine">{{
                        form.getErrorMessage(form.email) | translate
                    }}</mat-error>
                </div>
            </div>
            <div class="form-group row">
                <label for="input3" class="col-sm-4 col-form-label">{{ 'web.websites' | translate }}</label>
                <div class="col-sm-8">
                    <input
                        matInput
                        placeholder="{{ 'web.enter_websites' | translate }}"
                        formControlName="web"
                        class="form-control form-control-sm"
                    />
                </div>
            </div>
            <div class="form-group row">
                <label for="input3" class="col-sm-4 col-form-label">{{ 'web.text_message' | translate }}</label>
                <div class="col-sm-8">
                    <textarea
                        placeholder="{{ 'web.enter_text' | translate }}"
                        class="form-control form-control-sm"
                        cols="10"
                        rows="4"
                        formControlName="text"
                    ></textarea>
                    <mat-error *ngIf="form.text?.invalid && !form.text?.pristine">{{
                        form.getErrorMessage(form.text) | translate
                    }}</mat-error>
                </div>
            </div>
            <div>
                <ngx-recaptcha2
                    #captchaElem
                    [siteKey]="siteKey"
                    (load)="handleLoadRC()"
                    (success)="handleSuccessRC($event)"
                    [useGlobalDomain]="false"
                    [hl]="lang.getLangSnapshot()"
                    [type]="'Checkbox'"
                    formControlName="recaptcha"
                >
                </ngx-recaptcha2>

                <span class="text-danger" *ngIf="form.recaptcha?.invalid">{{
                    form.getErrorMessage(form.recaptcha) | translate
                }}</span>
            </div>
            <p class="text-right">
                <button class="btn btn-primary rounded-0" [disabled]="form.getGroup().invalid" (click)="send()">
                    {{ 'web.' + submitText | translate }}
                </button>
                &nbsp;&nbsp;
                <button class="btn btn-outline-secondary" type="reset" (click)="resetForm()">
                    {{ 'web.reset_form' | translate }}
                </button>
            </p>
        </div>
    </div>
</form>
