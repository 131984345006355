import { Component, OnInit } from '@angular/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { InfoService } from 'src/app/services/info.service';
import { Router } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';

import { ClientService } from '../../../services/client.service';
import { BaseComponent } from '../../base.component';

@Component({
    selector: 'app-lviescore',
    templateUrl: './livescore.component.html',
})
export class LivescoreComponent extends PageMixin(BaseComponent) implements OnInit {
    public constructor(
        public page: PageService,
        protected translate: TranslateService,
        protected client: ClientService,
        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,
        public lang?: LangService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.lang!.getLang().then((): Promise<any> => this.createTitle());

        this.page.setPageTitle('web.page_livescore');
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get('web.title_livescore').subscribe((): void => {
            const trans = this.translate.instant('web.title_lviescore');
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${trans} | ${client}`,
                    description: this.translate.instant('web.description_livescore'),
                },
                true,
            );
        });
    }
}
