<div class="row">
    <div class="col">
        <nav class="app-regular__list">
            <ul class="pl-0">
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.application_1' | translate }}
                </li>
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.application_2' | translate }}
                </li>
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.application_3' | translate }}
                </li>
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.application_4' | translate }}
                </li>
            </ul>
        </nav>
    </div>
</div>

<div class="row">
    <div class="col mb-4">
        <img src="/img/application_opt.png?v2" alt="Mobile application" class="img-fluid" />
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="d-flex d-flex justify-content-between">
            <div
                class="about-info-item d-flex flex-column justify-content-center align-items-center text-center"
                style="width: auto"
            >
                <a href="https://apps.apple.com/us/app/24live/id6504604967">
                    <img
                        src="/img/app-store-icon.png"
                        alt="You can use on mobile phone"
                        class="img-fluid"
                        style="width: 65%"
                    />
                </a>
                <p class="mb-0 about-info-item__text mt-2"><i class="fas fa-download fa-3x"></i></p>
            </div>
            <div
                class="about-info-item d-flex flex-column justify-content-center align-items-center text-center"
                style="width: auto"
            >
                <a href="https://play.google.com/store/apps/details?id=com.app24live.mobile">
                    <img
                        src="/img/google-play-icon.png"
                        alt="Thousands of matches"
                        class="img-fluid"
                        style="width: 65%"
                    />
                </a>
                <p class="mb-0 about-info-item__text mt-2"><i class="fas fa-download fa-3x"></i></p>
            </div>
        </div>
    </div>
</div>
