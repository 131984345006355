/**
 *  Advertisement form
 *  @author Livescore <jsmith@example.com>
 *  @copyright 2019 livescore
 */

import { Injectable } from '@angular/core';
import {
    FormControl, Validators, FormGroup, AbstractControl,
} from '@angular/forms';

import { BaseForm } from './base.form';

@Injectable({
    providedIn: 'root',
})
export class AdvertiseForm extends BaseForm {
    constructor() {
        super();
        this.init();
    }

    /**
   * Initialize form Controls
   * @return {void}
   */
    protected init(): void {
        super.init();
        this.formGroup = new FormGroup({
            name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
            email: new FormControl('', [Validators.required, Validators.email]),
            web: new FormControl(''),
            text: new FormControl('', [Validators.required, Validators.maxLength(1000)]),
            recaptcha: new FormControl('', Validators.required),
        });
    }

    get email(): AbstractControl | null {
        return this.formGroup.get('email');
    }

    get name(): AbstractControl | null {
        return this.formGroup.get('name');
    }

    get web(): AbstractControl | null {
        return this.formGroup.get('web');
    }

    get text(): AbstractControl | null {
        return this.formGroup.get('text');
    }

    get recaptcha(): AbstractControl | null {
        return this.formGroup.get('recaptcha');
    }
}
