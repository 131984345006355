import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageRegularComponent } from '@pages/page-regular/page-regular.component';
import { ContactComponent } from '@pages/page-regular/contact/contact.component';
import { ConditionsComponent } from '@pages/page-regular/conditions/conditions.component';
import { AdvertiseComponent } from '@pages/page-regular/advertise/advertise.component';
import { PrivacyComponent } from '@pages/page-regular/privacy/privacy.component';
import { AboutUsComponent } from '@pages/page-regular/about-us/about-us.component';
import { AddToHomescreenComponent } from '@pages/page-regular/add-to-homescreen/add-to-homescreen.component';
import { LivescoreComponent } from '@pages/page-regular/livescore/livescore.component';
import { ApplicationComponent } from '@pages/page-regular/application/application.component';


const routes: Routes = [
    {
        path: '',
        component: PageRegularComponent,
        children: [
            { path: 'contact', component: ContactComponent },
            { path: 'condition', component: ConditionsComponent },
            { path: 'livescore', component: LivescoreComponent },
            { path: 'advert', component: AdvertiseComponent },
            { path: 'privacy', component: PrivacyComponent },
            { path: 'about-us', component: AboutUsComponent },
            { path: 'add-to-home', component: AddToHomescreenComponent },
            { path: 'application', component: ApplicationComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class RegularRoutingModule {}
