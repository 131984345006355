<div class="row">
    <div class="col-md">
        <article *ngIf="lang?.getLangSnapshot() == 'cs'; else default">
            <p class="text-justify">
                Všechny informace mají pouze informační charakter a za jejich správnost neručíme. Přesto se snažíme, aby
                byly vždy 100 % přesné, především pak aktuální skóre a konečné výsledky.
            </p>
            <p class="text-justify">
                Bez písemného povolení není návštěvník oprávněn nikterak manipulovat s obsahem stránky jako např.
                kopírovat, reprodukovat, distribuovat nebo stahovat či jakkoli upravovat.
            </p>
            <p class="text-justify">
                Pokud provozovatel 24live zjistí jakékoli neoprávněné použití obsahu z webové stránky 24live, vyhrazuje
                si právo se bránit a případně také vymáhat náhradu způsobené škody.
            </p>
            <p class="text-justify">
                24live může obsahovat reklamní bannery ve formě obrázků, GIFu nebo videa, odkazy na partnerské weby a
                další obsah třetích stran. Obsah byl před uvedením na webové stránky 24live schválen provozovatelem a
                návštěvník si je vědom, že sdělení bude obsahovat obsah vhodný především pro návštěvníky, kterým je 18
                let a víc.
            </p>
            <p class="text-justify">
                Provozovatelem zveřejněná reklama formou odkazu (např. banner, GIF nebo obrázek) a obsah nacházející se
                na těchto internetových stránkách je plně v kompetenci daného poskytovale a provozovatel 24live není
                odpovědný za jakýkoli následující obsah, smlouvy, výhry nebo prohry, které bude moci návštěvník na
                těchto stránkách uvidět, uzavřít. Provozovatel 24live nenese žádnou odpovědnost a je zcela vyňat z
                možného sporu, který může nastat mezi návštěvníkem a třetí stranou.
            </p>
            <p class="text-justify">
                Provozovatel 24live si vyhrazuje právo „Podmínky užití“ podle vlastního uvážení kdykoli upravovat,
                aktualizovat či měnit a je na návštěvníkovi, aby si dané podmínky přečetl při návštěvě této webové
                stránky.
            </p>
            <p class="text-justify">
                Návštěvník používá stránku 24live spolu se všemi funkcemi kompletně na své vlastní nebezpečí. 24live
                není portálem a aplikací pro provozování her jakéhokoli druhu, proto nepřijímáme vaše finanční
                prostředky a nepodílíme se na žádných transakcích souvisejících s takovou činností. Zobrazované sázkové
                kurzy mají pouze informační charakter. Žádný zveřejněný obsah nepředstavuje doporučení účasti na hře
                nebo jakékoli poradenství v souvislosti s hraním.
            </p>
            <p class="text-justify">
                Jakýkoli video obsah zobrazený na webových stránkách 24live je a, buď originální obsah nebo b, video
                odkaz třetích stran např. z YouTube, který slouží k dokreslení výsledku sportovní události, poskytovaný
                společností Google Inc.. Společnost Google Inc. umožnuje, aby tato aplikace zprostředkovala obsah tohoto
                typu ze svých stránkek.
            </p>
        </article>

        <ng-template #default>
            <p class="text-justify">
                All information is purely informative and we do not guarantee its correctness. However we try to always
                be 100% accurate, especially then the current scores and the final results.
            </p>
            <p class="text-justify">
                Without a written permission the visitor is not permitted to manipulate the content of the site, such as
                copy, reproduce, distribute or download and edit it in any way.
            </p>
            <p class="text-justify">
                If the 24live operator discovers any unauthorized use of the content from the 24live website, it
                reserves the right to defend itself and, where appropriate, to claim damages.
            </p>
            <p class="text-justify">
                24live can contain AD banners in the form of images, GIFs or videos, links to partner sites and other
                third-party content. The content was approved by the operator prior publishing to the 24live website and
                the visitor is aware that the message will contain content that is particularly suited to visitors aged
                18 and over.
            </p>
            <p class="text-justify">
                Adverts by means of a link (e.g. a banner, GIF or image) and content located on these websites is fully
                within the competence of the given provider of the advert and the 24live operator is not responsible for
                any of the following content, contracts, any winnings or losses that a visitor can later see, get or
                sign. The operator of 24live assumes no responsibility and is completely exempted from any possible
                dispute that may arise between the visitor and the third party.
            </p>
            <p class="text-justify">
                Operator 24live reserves the right to modify, update and change the "Terms of Use" at its sole
                discretion at any time and it is up to the visitor to read the terms and conditions when visiting this
                website.
            </p>
            <p class="text-justify">
                The visitor uses the 24live page along with all the features completely at his own risk.
            </p>
            <p class="text-justify">
                24live is not a portal and application for running games of any kind, therefore we do not accept your
                funds and do not participate in any transactions related to such activity. The displayed betting odds
                are for informational purposes only. None of the posted content constitutes a recommendation to
                participate in the game or any advice regarding gameplay.
            </p>
            <p class="text-justify">
                Any video content displayed on the 24live website is a, either original content or b, a third-party
                video link, such as from YouTube, to illustrate the outcome of a sports event provided by Google Inc..
                Google Inc. allows this app to convey content of this type from its site.
            </p>
        </ng-template>
    </div>
</div>
