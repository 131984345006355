<div class="pocket mx-auto py-7">
    <div class="row">
        <div class="col col-md-6 col-xxl-5">
            <h1 class="mb-1 text-center text-md-left">{{ 'web.add_home_title' | translate }}</h1>
            <h2 class="h4 text-center text-md-left">{{ 'web.add_home_subtitle' | translate }}</h2>

            <ul class="nav nav-pills mt-5 mb-md-4 nav-pills--switch" id="systems" role="tablist">
                <li class="nav-item">
                    <a
                        class="nav-link btn btn-light active d-flex align-content-center px-4 py-2"
                        id="ios-tab"
                        (click)="tab('ios')"
                        data-toggle="tab"
                        href="#ios"
                        role="tab"
                        aria-controls="ios"
                        aria-selected="true"
                        ><svg class="icon mr-2">
                            <use href="#icon-apple" /></svg
                        >iOS</a
                    >
                </li>
                <li class="nav-item">
                    <a
                        class="nav-link btn btn-light d-flex align-content-center px-4 py-2"
                        id="android-tab"
                        (click)="tab('android')"
                        data-toggle="tab"
                        href="#android"
                        role="tab"
                        aria-controls="android"
                        aria-selected="false"
                        ><svg class="icon mr-2">
                            <use href="#icon-android" /></svg
                        >Android</a
                    >
                </li>
            </ul>

            <img [src]="img" class="img-fluid mx-auto mb-6 position-relative d-block  z-index-n1" alt="" />

            <div class="tab-content px-5 px-md-0">
                <div class="tab-pane fade show active" id="ios" role="tabpanel" aria-labelledby="ios-tab">
                    <div class="d-flex">
                        <span class="circle bg-primary rounded-circle mr-3">1.</span>
                        <div>
                            <h3 class="h5 text-uppercase font-weight-bold">
                                {{ 'web.add_home_step1_head_ios' | translate }}
                            </h3>
                            <p>{{ 'web.add_home_step1_text_ios' | translate }}</p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <span class="circle bg-primary rounded-circle mr-3">2.</span>
                        <div>
                            <h3 class="h5 text-uppercase font-weight-bold">
                                {{ 'web.add_home_step2_head_ios' | translate }}
                            </h3>
                            <p>{{ 'web.add_home_step2_text_ios' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="android" role="tabpanel" aria-labelledby="android-tab">
                    <div class="d-flex">
                        <span class="circle bg-primary rounded-circle mr-3">1.</span>
                        <div>
                            <h3 class="h5 text-uppercase font-weight-bold">
                                {{ 'web.add_home_step1_head_android' | translate }}
                            </h3>
                            <p>{{ 'web.add_home_step1_text_android' | translate }}</p>
                        </div>
                    </div>
                    <div class="d-flex">
                        <span class="circle bg-primary rounded-circle mr-3">2.</span>
                        <div>
                            <h3 class="h5 text-uppercase font-weight-bold">
                                {{ 'web.add_home_step2_head_android' | translate }}
                            </h3>
                            <p>{{ 'web.add_home_step2_text_android' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
