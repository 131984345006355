<div class="row">
    <div class="col">
        <nav class="app-regular__list">
            <ul class="pl-0">
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.livescore_1' | translate }}
                </li>
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.livescore_2' | translate }}
                </li>
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.livescore_3' | translate }}
                </li>
                <li class="list-item">
                    <i class="fas fa-check-circle icon pr-5"></i> {{ 'web.livescore_4' | translate }}
                </li>
            </ul>
        </nav>
    </div>
</div>

<div class="row">
    <div class="col mb-4">
        <img src="/img/24live_livescore_livesport.jpg?v2" alt="You can use on mobile phone" class="img-fluid" />
    </div>
</div>
<div class="row">
    <div class="col">
        <div class="d-flex d-flex justify-content-between">
            <div class="about-info-item d-flex flex-column justify-content-center align-items-center">
                <img src="/img/ico_cup.svg" alt="You can use on mobile phone" class="img-fluid" />
                <p class="mb-0 about-info-item__text">{{ 'web.livescore_more_than' | translate }}</p>
                <p class="mb-0 about-info-item__text about-info-item__text--emph">9000</p>
                <p class="mb-0 about-info-item__text text-center">{{ 'web.about_competition' | translate }}</p>
            </div>
            <div class="about-info-item d-flex flex-column justify-content-center align-items-center">
                <img src="/img/ico_ball.svg" alt="Thousands of matches" class="img-fluid" />
                <p class="mb-0 about-info-item__text">{{ 'web.livescore_more_than' | translate }}</p>
                <p class="mb-0 about-info-item__text about-info-item__text--emph">40 000</p>
                <p class="mb-0 about-info-item__text text-center">{{ 'web.livescore_matches' | translate }}</p>
            </div>
            <div class="about-info-item d-flex flex-column justify-content-center align-items-center">
                <img src="/img/ico_live.svg" alt="Live matches" class="img-fluid" />
                <p class="mb-0 about-info-item__text">{{ 'web.livescore_more_than' | translate }}</p>
                <p class="mb-0 about-info-item__text about-info-item__text--emph">30 000</p>
                <p class="mb-0 about-info-item__text text-center">{{ 'web.livescore_live_matches' | translate }}</p>
            </div>
        </div>
    </div>
</div>

<div class="row mt-6">
    <div class="col-12">
        <div class="about-sport about-sport--soccer mb-4">
            {{ 'sport.soccer' | translate }}
            <span class="--small">1012 {{ 'web.about_competition' | translate }}</span>
            <span class="--dashed">&nbsp;</span>
        </div>
        <p class="text-justify">
            {{ 'web.livescore_soccer' | translate }}
        </p>
    </div>
    <div class="col-12">
        <div class="about-sport about-sport--hockey mb-4">
            {{ 'sport.ice-hockey' | translate }}
            <span class="--small">156 {{ 'web.about_competition' | translate }}</span>
            <span class="--dashed">&nbsp;</span>
        </div>
        <p class="text-justify">
            {{ 'web.livescore_hockey' | translate }}
        </p>
    </div>
    <div class="col-12">
        <div class="about-sport about-sport--tennis mb-4">
            {{ 'sport.tennis' | translate }} <span class="--small">4618 {{ 'web.about_competition' | translate }}</span>
            <span class="--dashed">&nbsp;</span>
        </div>
        <p class="text-justify">
            {{ 'web.livescore_tennis' | translate }}
        </p>
    </div>
    <div class="col-12">
        <div class="about-sport about-sport--basketball mb-4">
            {{ 'sport.basketball' | translate }}
            <span class="--small">320 {{ 'web.about_competition' | translate }}</span>
            <span class="--dashed">&nbsp;</span>
        </div>
        <p class="text-justify">
            {{ 'web.livescore_basketball' | translate }}
        </p>
    </div>
    <div class="col-12">
        <div class="about-sport about-sport--other mb-4">
            {{ 'web.livescore_more_competitions' | translate }}

            <span class="--dashed">&nbsp;</span>
        </div>
        <p class="text-justify">
            {{ 'web.livescore_other' | translate }}
        </p>
    </div>
</div>
