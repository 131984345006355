<app-header [fullMenu]="false"></app-header>

<main role="main">
    <div class="container p-2 pt-md-6 p-md-4 position-relative app-regular">
        <div class="row">
            <div class="col-md-7 order-2">
                <div class="pl-md-4 app-sub-see-pages">
                    <router-outlet></router-outlet>
                </div>
            </div>

            <div class="col-md-3 offset-md-1 order-1 col--user">
                <h1 class="h2 font-weight-bold mb-5">{{ page.getPageTitle() | translate }}</h1>

                <nav class="nav flex-column d-none d-md-flex">
                    <a [routerLink]="['/page/see/about-us']" routerLinkActive="active-link" class="nav-link">
                        {{ 'web.page_about_us' | translate }}</a
                    >
                    <a [routerLink]="['/page/see/livescore']" routerLinkActive="active-link" class="nav-link">
                        {{ 'web.livescore' | translate }}</a
                    >
                    <a [routerLink]="['/page/see/advert']" routerLinkActive="active-link" class="nav-link">
                        {{ 'web.advertisement' | translate }}</a
                    >
                    <!-- <a [routerLink]="['/page/see/condition']" routerLinkActive="active-link" class="nav-link">
                        {{ 'web.page_conditions' | translate }}</a
                    >

                    <a [routerLink]="['/page/see/privacy']" routerLinkActive="active-link" class="nav-link">
                        {{ 'web.privacy_cookies' | translate }}</a
                    > -->
                    <a [routerLink]="['/page/see/contact']" routerLinkActive="active-link" class="nav-link">{{
                        'web.contact_us' | translate
                    }}</a>
                    <a [routerLink]="['/page/see/application']" routerLinkActive="active-link" class="nav-link">{{
                        'web.mobile_application' | translate
                    }}</a>
                    <!-- <a routerLink="/page/see/add-to-home" class="nav-link "
                        ><i class="fas fa-user-lock"></i> {{ 'web.add_to_homescreen' | translate }}</a
                    > -->
                </nav>
            </div>
        </div>
    </div>
</main>

<app-footer></app-footer>
